import {
  createInventory,
  fetchInventory,
  updateInventory,
} from "@/modules/warehouses/inventories/services/inventories.services"
import { createUnit, fetchUnits } from "@/modules/warehouses/inventories/services/units.services"
import { InventoryType, Mapped_unitsGroup, UnitGroupEnum, UnitType } from "@/modules/warehouses/inventories/types"
import { normalizeText, notificationAction } from "@/utils"
import { NotificationEnum, StatusEnum } from "@/utils/constants"
import { PlusOutlined } from "@ant-design/icons"
import { Button, Divider, Form, Input, Modal, Select, Space } from "antd"
import { InputNumber } from "antd/lib"
import { useEffect, useState } from "react"

interface BaseUpsertForm {
  openProps?: boolean
  cancelProps: () => void
  refreshProps: () => void
  actionType?: string
  idEdit?: string
}

const UpsertForm: React.FC<BaseUpsertForm> = props => {
  const [loading, setLoading] = useState(false)
  const [formInventory] = Form.useForm()
  const [formUnit] = Form.useForm()
  const [isOpenModelAdd, setIsOpenModelAdd] = useState<boolean>(false)
  const [dataUnits, setDataUnits] = useState<UnitType[]>()
  const [inventoryData, setInventoryData] = useState<InventoryType | object>({})

  const getUnits = async () => {
    try {
      const response = await fetchUnits()
      if (response?.data) {
        setDataUnits(response.data)
      }
    } catch (error) {
      console.error("Error fetching positions:", error)
    }
  }

  useEffect(() => {
    getUnits()
  }, [isOpenModelAdd])

  const onReset = () => {
    formInventory.resetFields()
    formUnit.resetFields()
  }

  const handleDismissModal = () => {
    if (isOpenModelAdd) {
      setIsOpenModelAdd(false)
    } else {
      props.cancelProps()
      onReset()
    }
  }

  const handleCompleteForm = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      onReset()
      props.refreshProps()
      handleDismissModal()
    }, 1000)
  }

  const handleAdd = async () => {
    try {
      if (isOpenModelAdd === false) {
        const values = await formInventory.validateFields()
        if (values.weight || values.quantity) {
          const newData: InventoryType = {
            status: StatusEnum.ACTIVE,
            ...values,
          }
          const response = await createInventory(newData)
          if (response.statusCode === 200) {
            handleCompleteForm()
            notificationAction(NotificationEnum.CREATED, NotificationEnum.SUCCESS)
          }
        } else notificationAction("Vui lòng nhập thông tin đúng vào form! ", NotificationEnum.ERROR)
      } else {
        const values = await formUnit.validateFields()
        const newData: UnitType = {
          name: values.name.toString(),
          unit_group: values.unit_group,
        }
        const response = await createUnit(newData)
        if (response.statusCode === 200) {
          handleCompleteForm()
          notificationAction(NotificationEnum.CREATED, NotificationEnum.SUCCESS)
        }
      }
    } catch (error) {
      console.error("Addition failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  useEffect(() => {
    if (props.actionType === "CREATE") {
      formInventory.resetFields()
    }

    if (props.actionType === "UPDATE" && props.idEdit) {
      fetchInventory(props.idEdit).then(res => {
        formInventory.setFieldsValue({
          name: res.data.name,
          quantity: res.data.quantity,
          unit_id: res.data.unit_id,
        })
        setInventoryData(res.data)
      })
    }
  }, [props.idEdit, props.actionType])

  const handleEdit = async () => {
    try {
      if (props.idEdit) {
        const values = await formInventory.validateFields()
        if (values.weight || values.quantity) {
          const response = await updateInventory(props.idEdit, {
            ...inventoryData,
            ...values,
          })
          if (response.statusCode === 200) {
            handleCompleteForm()
            notificationAction(NotificationEnum.UPDATED, NotificationEnum.SUCCESS)
          }
        }
      }
      notificationAction("Vui lòng nhập đúng thông tin! ", NotificationEnum.ERROR)
    } catch (error) {
      console.error("Edit failed:", error)
      notificationAction(`Lỗi ${error}`, NotificationEnum.ERROR)
    }
  }

  const renderFooter = () => {
    return (
      <>
        <Space>
          <Button
            onClick={() => {
              onReset()
            }}
          >
            Làm mới
          </Button>
          <Button key="cancel" onClick={handleDismissModal}>
            Hủy
          </Button>
          {props.actionType === "UPDATE" && (
            <Button type="primary" onClick={handleEdit} loading={loading}>
              Lưu
            </Button>
          )}
          {props.actionType === "CREATE" && (
            <Button type="primary" onClick={() => handleAdd()} loading={loading}>
              Thêm mới
            </Button>
          )}
        </Space>
      </>
    )
  }

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    normalizeText((option?.label ?? "").toLowerCase()).includes(normalizeText(input.toLowerCase()))

  return (
    <>
      <Modal
        title={props.actionType === "CREATE" ? "Thêm hàng hóa" : "Quản lí hàng hóa"}
        open={!isOpenModelAdd && props.openProps}
        width={600}
        onCancel={props.cancelProps}
        footer={renderFooter()}
      >
        <Form
          name="wrap"
          labelCol={{ flex: "140px" }}
          labelAlign="left"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          style={{ maxWidth: 600 }}
          form={formInventory}
        >
          <Form.Item
            label="Tên hàng hoá"
            name="name"
            rules={[{ required: true, message: "Vui lòng nhập dữ liệu vào!" }]}
          >
            <Input placeholder="Nhập tên hàng hóa" />
          </Form.Item>
          <Form.Item
            label="Số lượng:"
            name="quantity"
            rules={[{ required: true, message: "Vui lòng nhập dữ liệu vào!" }]}
          >
            <InputNumber
              placeholder="Nhập số lượng"
              step={1}
              min={0}
              style={styles.inputNumber}
              disabled={props.actionType === "CREATE" ? false : true}
              readOnly={props.actionType === "CREATE" ? false : true}
            />
          </Form.Item>
          <Form.Item label="Đơn vị" name="unit_id" rules={[{ required: true, message: "Vui lòng chọn đơn vị!" }]}>
            <Select
              placeholder="Vui lòng chọn đơn vị"
              filterOption={filterOption}
              optionFilterProp="children"
              options={dataUnits?.map(unit => ({ value: unit?.id ?? "", label: unit.name }))}
              dropdownRender={menu => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsOpenModelAdd(true)}>
                      Thêm đơn vị
                    </Button>
                  </Space>
                </>
              )}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={"Thêm loại đơn vị"}
        open={isOpenModelAdd}
        width={600}
        onCancel={handleDismissModal}
        footer={renderFooter()}
      >
        <Form
          name="wrap"
          labelCol={{ flex: "158px" }}
          labelAlign="left"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          style={{ maxWidth: 600 }}
          form={formUnit}
        >
          <Form.Item label="Tên đơn vị" name="name" rules={[{ required: true, message: "Vui lòng nhập dữ liệu vào!" }]}>
            <Input placeholder="Nhập tên đơn vị" />
          </Form.Item>

          <Form.Item
            label="Loại đơn vị"
            name="unit_group"
            rules={[{ required: true, message: "Vui lòng nhập dữ liệu vào!" }]}
          >
            <Select
              placeholder="Vui lòng chọn loại đơn vị"
              style={{ width: "100%" }}
              options={[
                { value: UnitGroupEnum.QUANTITY, label: Mapped_unitsGroup[UnitGroupEnum.QUANTITY] },
                { value: UnitGroupEnum.WEIGHT, label: Mapped_unitsGroup[UnitGroupEnum.WEIGHT] },
                { value: UnitGroupEnum.VOLUME, label: Mapped_unitsGroup[UnitGroupEnum.VOLUME] },
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
export default UpsertForm

const styles = {
  inputNumber: {
    width: "410px",
  },
}
