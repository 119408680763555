import {
  Chart,
  ChartReceiptCategory,
  ExpenseReceiptCategory,
  IncomeExpense,
  IncomeExpenseStatistic,
  IncomeReceiptCategory,
  PayableDebts,
  ReceivableDebts,
  STATISTIC_DATES,
  STATISTIC_RECEIPTS,
  StaffStatisticType,
  StatisticCard,
} from "@/modules/dashboard/types"
import { TypeReceipts } from "@/modules/finances/receipts/types"
import store from "@/redux/store"
import { NotificationEnum } from "@/utils/constants"
import size from "lodash/size"
import { ValueType } from "rsuite/esm/InputPicker/InputPicker"
import { ActionTypes, GETLIST_DEFAULT, PAGINATION_DEFAULT } from "../constants"

export const getInitParams = (search = "") => {
  const paramsFromUrl = window.location.search
    .substring(1)
    .split("&")
    .map(item => item.split("="))
  if (!paramsFromUrl[0][0]) {
    return {
      ...PAGINATION_DEFAULT,
      ...GETLIST_DEFAULT,
    }
  }

  const result = paramsFromUrl.reduce((result: any, quote: any) => {
    result[quote[0]] = quote[1]
    return result
  }, {})

  const params = {
    ...PAGINATION_DEFAULT,
    ...result,
  }

  return params
}

export const convertObjectParamsToStringParams = (object: any) => {
  const arrayParams = Object.entries(object)
  return arrayParams.reduce((result, quote, key) => {
    result = result + `${quote[0]}=${quote[1]}${key !== size(arrayParams) - 1 ? "&" : ""}`
    return result
  }, "")
}

export const parseJwt = (token: string) => {
  try {
    const base64Url = token.split(".")[1]
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(""),
    )

    return JSON.parse(jsonPayload)
  } catch (error) {
    return null
  }
}

export const formatMoney = (money: number, fixed = 0) => {
  return money.toFixed(fixed).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
}

export const getExpectedWorkDays = (month: number, year: number, workDays: number[]) => {
  const endOfMonthDate = new Date(year, month, 0)
  let expectedWorkDays = 0
  for (let i = 1; i <= endOfMonthDate.getDate(); i++) {
    const date = new Date(year, month - 1, i)
    if (workDays.includes(date.getDay())) {
      expectedWorkDays++
    }
  }

  return expectedWorkDays
}

export const filterOption = (input: string, option?: { label: string; value: string }) =>
  normalizeText((option?.label ?? "").toLowerCase()).includes(normalizeText(input.toLowerCase()))

const getArrayStatisticData = (chartData: any, type: string) => {
  const nearestMonth: any[] = []
  const date = new Date()
  date.setDate(1)
  for (let i = 0; i < 11; i++) {
    nearestMonth.push(new Date(date.getFullYear(), date.getMonth() - i, 1))
  }
  const resultData = Array(12).fill(0)
  chartData.forEach((item: any) => {
    const itemDate = new Date(item.month)
    for (let i = 0; i < nearestMonth.length; i++) {
      const comparisonDate = nearestMonth[i]
      if (checkIsSameDate(itemDate, comparisonDate)) {
        const index = 11 - i
        resultData[index] = Math.round(item.income) || Math.round(item.amount) || 0
        break
      }
    }
  })

  return resultData
}

export const convertChartData = (data?: any, typeChart?: string) => {
  if (typeChart === "staffs") {
    const staffChart = getArrayStatisticData(data?.staffs.chart, "staffs")
    return [
      {
        name: "Lương thực tế",
        data: staffChart,
        offsetY: 0,
        color: "#b37feb",
        suffix: "VNĐ",
      },
    ]
  } else {
    const payableData = getArrayStatisticData(data?.payable_debts.chart, "debts")
    const receivableData = getArrayStatisticData(data?.receivable_debts.chart, "debts")
    return [
      {
        name: "Công nợ thu",
        data: receivableData,
        offsetY: 0,
        color: "#1890ff",
        suffix: "VNĐ",
      },
      {
        name: "Công nợ trả",
        data: payableData,
        offsetY: 0,
        color: "#ff8018",
        suffix: "VNĐ",
      },
    ]
  }
}

export const transformReceiptStatistic = (data: IncomeExpenseStatistic): StatisticCard[] => {
  const incomeStatistic = mapIncomeExpenseStatistic(data.income, TypeReceipts.INCOME)
  const expenseStatistic = mapIncomeExpenseStatistic(data.expense, TypeReceipts.EXPENSE)

  return [...incomeStatistic, ...expenseStatistic]
}

const mapIncomeExpenseStatistic = (data: IncomeExpense[], type: TypeReceipts): StatisticCard[] => {
  const statistic = data.map((item: IncomeExpense) => {
    return {
      name: `${STATISTIC_RECEIPTS[type]} ${STATISTIC_DATES[item.name as keyof typeof STATISTIC_DATES]}`,
      value: item.value ?? 0,
    }
  })

  return statistic
}

const isPayableDebts = (debts: PayableDebts | ReceivableDebts): debts is PayableDebts => {
  return (debts as PayableDebts).total_payable_debts !== undefined
}

export const transformDebtStatistic = (data: PayableDebts | ReceivableDebts): StatisticCard => {
  return isPayableDebts(data)
    ? { name: "Tổng nợ phải trả", value: data.total_payable_debts ?? 0 }
    : { name: "Tổng nợ phải thu", value: data.total_receivable_debts ?? 0 }
}

export const transformStaffStatistic = (data: StaffStatisticType): StatisticCard[] => {
  const totalStaff = { name: "Nhân viên", value: data.count ?? 0 }
  const basicIncome = { name: "Lương cơ bản", value: data.basic_income ?? 0 }
  const date = new Date()
  const finalIncome = {
    name: `Lương thực tế tháng ${date.getMonth() + 1}`,
    value: data.final_income ?? 0,
  }
  return [totalStaff, basicIncome, finalIncome]
}

export const convertReceiptChartData = (chartData: Chart) => {
  const incomeData = Array(12).fill(0)
  const expensesData = Array(12).fill(0)

  const concatData = [...chartData.income, ...chartData.expense]

  const nearestMonth: any[] = []
  const date = new Date()
  date.setDate(1)
  for (let i = 0; i < 11; i++) {
    nearestMonth.push(new Date(date.getFullYear(), date.getMonth() - i, 1))
  }
  concatData.forEach((item: any) => {
    const itemDate = new Date(item.month)
    for (let i = 0; i < nearestMonth.length; i++) {
      const comparisonDate = nearestMonth[i]
      if (checkIsSameDate(itemDate, comparisonDate)) {
        const index = 11 - i
        if (item.receipts_type === "income") {
          incomeData[index] += item.value
        } else {
          expensesData[index] += item.value
        }
        break
      }
    }
  })

  const incomeObject = {
    name: "Thu",
    data: incomeData,
    offsetY: 0,
    color: "#1890ff",
    suffix: "VNĐ",
  }

  const expensesObject = {
    name: "Chi",
    data: expensesData,
    offsetY: 0,
    color: "#ff8018",
    suffix: "VNĐ",
  }
  return [incomeObject, expensesObject]
}

export const convertReceiptIncomeCategoryChart = (chartData: ChartReceiptCategory) => {
  const categoryDataMap: { [key: string]: number[] } = {}

  const nearestMonth: Date[] = []
  const date = new Date()
  date.setDate(1)

  for (let i = 0; i < 12; i++) {
    nearestMonth.push(new Date(date.getFullYear(), date.getMonth() - i, 1))
  }

  chartData.income.forEach((item: IncomeReceiptCategory) => {
    const itemDate = new Date(item.month)
    const categoryName = item.category_name

    if (!categoryDataMap[categoryName]) {
      categoryDataMap[categoryName] = Array(12).fill(0)
    }

    const index = nearestMonth.findIndex(date => checkIsSameDate(itemDate, date))
    if (index !== -1) {
      categoryDataMap[categoryName][11 - index] += item.value
    }
  })

  return Object.entries(categoryDataMap).map(([categoryName, data]) => ({
    name: categoryName,
    data: data,
    offsetY: 0,
    color: getRandomColor(),
    suffix: "VNĐ",
  }))
}

export const convertReceiptExpenseCategoryChart = (chartData: ChartReceiptCategory) => {
  const categoryDataMap: { [key: string]: number[] } = {}

  const nearestMonth: Date[] = []
  const date = new Date()
  date.setDate(1)

  for (let i = 0; i < 12; i++) {
    nearestMonth.push(new Date(date.getFullYear(), date.getMonth() - i, 1))
  }

  chartData.expense.forEach((item: ExpenseReceiptCategory) => {
    const itemDate = new Date(item.month)
    const categoryName = item.category_name

    if (!categoryDataMap[categoryName]) {
      categoryDataMap[categoryName] = Array(12).fill(0)
    }

    const index = nearestMonth.findIndex(date => checkIsSameDate(itemDate, date))
    if (index !== -1) {
      categoryDataMap[categoryName][11 - index] += item.value
    }
  })

  return Object.entries(categoryDataMap).map(([categoryName, data]) => ({
    name: categoryName,
    data: data,
    offsetY: 0,
    color: getRandomColor(),
    suffix: "VNĐ",
  }))
}

export const convertReceiptCategoryByDayChart = (chartData: ChartReceiptCategory) => {
  const incomeDataMap: { [key: string]: number[] } = {}
  const expenseDataMap: { [key: string]: number[] } = {}
  const nearestDays: string[] = []

  const today = new Date()

  for (let i = 0; i < 365; i++) {
    const date = new Date(today)
    date.setDate(today.getDate() - i)
    nearestDays.push(date.toISOString().split("T")[0])
  }

  chartData.income.forEach((item: any) => {
    const itemDate = new Date(item.day).toISOString().split("T")[0]
    const categoryName = item.category_name

    if (!incomeDataMap[categoryName]) {
      incomeDataMap[categoryName] = Array(365).fill(0)
    }

    const index = nearestDays.findIndex(date => date === itemDate)
    if (index !== -1) {
      incomeDataMap[categoryName][364 - index] += item.value
    }
  })

  chartData.expense.forEach((item: any) => {
    const itemDate = new Date(item.day).toISOString().split("T")[0]
    const categoryName = item.category_name

    if (!expenseDataMap[categoryName]) {
      expenseDataMap[categoryName] = Array(365).fill(0)
    }

    const index = nearestDays.findIndex(date => date === itemDate)
    if (index !== -1) {
      expenseDataMap[categoryName][364 - index] += item.value
    }
  })

  const incomeChartData = Object.entries(incomeDataMap).map(([categoryName, data]) => ({
    name: categoryName,
    data: data,
    offsetY: 0,
    color: getRandomColor(),
    suffix: "VNĐ",
  }))

  const expenseChartData = Object.entries(expenseDataMap).map(([categoryName, data]) => ({
    name: categoryName,
    data: data,
    offsetY: 0,
    color: getRandomColor(),
    suffix: "VNĐ",
  }))

  return { incomeChartData, expenseChartData }
}

const getRandomColor = () => {
  const letters = "0123456789ABCDEF"
  let color = "#"
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

export function getMonthStartEndDate(date: Date) {
  const startDate = formatFirstDateOfMonth(date)
  const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)

  return { startDate, endDate }
}

export function formatFirstDateOfMonth(date: Date | string) {
  const firstDate = new Date(date)
  firstDate.setDate(1)
  return firstDate
}

export function checkIsDateInMonth(date: Date, startDate: Date, endDate: Date) {
  return (
    date.setHours(0, 0, 0, 0).valueOf() >= startDate.setHours(0, 0, 0, 0).valueOf() &&
    date.setHours(0, 0, 0, 0).valueOf() <= endDate.setHours(0, 0, 0, 0).valueOf()
  )
}

export const checkIsSameDate = (date1: Date | string, date2: Date | string) => {
  const d1 = new Date(date1)
  const d2 = new Date(date2)

  return d1.toDateString() === d2.toDateString()
}

export const dateFormatList = "DD/MM/YYYY"
export const dateFormatListMonth = "MM/YYYY"

export const formatterInputNumber = (value: ValueType) => {
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
export const parserInputNumber = (value: string) => {
  return value!.replace(/\$\s?|(,*)/g, "")
}

export const notificationAction = (message: string, type: string) => {
  store.dispatch({
    type: ActionTypes.NOTIFICATION,
    payload: {
      type: `${type}`,
      message: `${message}`,
    },
  })
}

export const notificationCreateSuccess = () => notificationAction(NotificationEnum.CREATED, NotificationEnum.SUCCESS)

export const notificationUpdateSuccess = () => notificationAction(NotificationEnum.UPDATED, NotificationEnum.SUCCESS)

export const normalizeText = (text: string) => {
  return text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/Đ/g, "D")
}
