import Filters, { FilterComponent } from "@/components/Filters"
import { fetchLogs } from "@/modules/log/services/logs.services"
import { LogActions, LogType, Mapped_LogActions } from "@/modules/log/types"
import { User } from "@/modules/manages/users"
import { fetchUsers } from "@/modules/manages/users/services/users.services"
import { Partner } from "@/modules/partners/types"
import { ResponseData } from "@/types"
import { normalizeText } from "@/utils"
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons"
import { Button, Card, Flex, Space, Table, Typography } from "antd"
import { SorterResult } from "antd/es/table/interface"
import dayjs from "dayjs"
import queryString from "query-string"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { AnyObject } from "yup/lib/types"

type EditableTableProps = Parameters<typeof Table>[0]

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>

const List: React.FC = () => {
  const [dataSource, setDataSource] = useState<ResponseData<LogType>>()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [needRefresh, setNeedRefresh] = useState(false)
  const [idEdit, setIdEdit] = useState<string>("")
  const [actionType, setActionType] = useState<string>("")
  const [currentPage, setCurrentPage] = useState(1)
  const navigate = useNavigate()
  const location = useLocation()
  const filterRef = useRef<{ filterFunction: () => void } | null>(null)
  const [defaultFilterValues, setDefaultFilterValues] = useState<{ [key: string]: any } | null>(null)
  const [filterValues, setFilterValues] = useState<{ [key: string]: string } | null>(null)
  const [sortPaginationValues, setSortPaginationValues] = useState<{ [key: string]: string } | null>(null)
  const [dataUsers, setDataUsers] = useState<User[]>([])

  const onRefresh = () => {
    setNeedRefresh(!needRefresh)
    if (filterRef.current) {
      filterRef.current.filterFunction()
    }
    setSortPaginationValues(null)
  }

  const getUsers = async () => {
    try {
      const response = await fetchUsers()
      if (response?.data) {
        setDataUsers(response.data.items)
      }
    } catch (error) {
      console.error("Error fetching positions:", error)
    }
  }

  const onTableChanged = ((pagination, _, sorter: SorterResult<AnyObject>) => {
    const sortOptions: { [key: string]: string } = sorter.order
      ? { [sorter.field as string]: sorter.order === "descend" ? "desc" : "asc" }
      : {}
    const paginationOptions: { [key: string]: string } = {
      page: `${pagination.current}`,
      limit: `${pagination.pageSize}`,
    }

    if (pagination && pagination.current) {
      setCurrentPage(pagination.current)
    }

    setSortPaginationValues({ ...sortOptions, ...paginationOptions })
    const searchParams = queryString.stringify({ ...filterValues, ...sortOptions, ...paginationOptions })

    navigate(searchParams.length > 0 ? `${location.pathname}?${searchParams}` : location.pathname)
  }) as EditableTableProps["onChange"]

  const showModal = () => {
    setActionType("CREATE")
    setOpen(true)
  }

  const showModalEdit = (id: string) => {
    navigate(`/partners/${id}/${location.search}`)
    setIdEdit(id)
    setOpen(true)
    setActionType("UPDATE")
  }

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: "5%",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "25%",
      render: (action: LogActions) => {
        return Mapped_LogActions[action]
      },
    },
    {
      title: "Loại logs",
      dataIndex: "type",
      key: "type",
      width: "25%",
    },
    {
      title: "Người Make action",
      dataIndex: "user_id",
      key: "user_id",
      width: "15%",
      render: (user_id: string) => {
        const user = dataUsers.find(user => user.id === user_id)
        return user?.fullname
      },
    },
    {
      title: "Ngày diễn ra",
      dataIndex: "date",
      key: "date",
      render: date => date && dayjs(new Date(date)).format("HH:mm DD/MM/YYYY"),
      width: "20%",
    },
  ]

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record: Partner) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
  })

  const onSearch = useCallback(
    (values: any) => {
      let newValue = { ...values, ...sortPaginationValues }

      if (values.search) {
        newValue = { ...newValue, search: normalizeText(values.search) }
      }

      if (newValue.min_date) {
        newValue = { ...newValue, min_date: dayjs(newValue.min_date).format("YYYY-MM-DD") }
      }

      if (newValue.max_date) {
        newValue = { ...newValue, max_date: dayjs(newValue.max_date).format("YYYY-MM-DD") }
      }

      const searchParams = queryString.stringify(newValue)
      const newUrl = searchParams.length > 0 ? `${location.pathname}?${searchParams}` : location.pathname
      navigate(newUrl)
    },
    [sortPaginationValues],
  )

  useEffect(() => {
    if (!filterValues && !sortPaginationValues) return
    const params = { ...filterValues, ...sortPaginationValues }
    setLoading(true)
    fetchLogs(params).then((res: any) => {
      if (sortPaginationValues && sortPaginationValues.page) {
        if (sortPaginationValues.page > res.data.meta.totalPages && res.data.meta.totalPages > 0) {
          const searchLocation = queryString.parse(location.search)
          const newParams = queryString.stringify({ ...searchLocation, page: `${res.data.meta.totalPages}` })
          const newUrl = newParams.length > 0 ? `${location.pathname}?${newParams}` : location.pathname
          navigate(newUrl)
        }
      }
      setDataSource(res.data)
      setLoading(false)
    })
  }, [filterValues, sortPaginationValues, needRefresh])

  useEffect(() => {
    const searchParams = queryString.parse(location.search)
    const filterValues: { [key: string]: string } = {}
    const sortPaginationValues: { [key: string]: string } = {}
    for (const key of Object.keys(searchParams)) {
      const value = searchParams[key]
      if (value && typeof value === "string") {
        if (key === "page" || key === "limit" || value === "asc" || value === "desc") {
          sortPaginationValues[key] = value
        } else {
          filterValues[key] = value
        }
      }
    }

    setFilterValues(filterValues)
    setSortPaginationValues(sortPaginationValues)
  }, [location.search])

  useEffect(() => {
    getUsers()

    const searchParams = queryString.parse(location.search)
    const filterValues: { [key: string]: any } = {}
    const sortPaginationValues: { [key: string]: string } = {}
    for (const key of Object.keys(searchParams)) {
      const value = searchParams[key]
      if (value && typeof value === "string") {
        if (value === "true" || value === "false") {
          filterValues[key] = value === "true" ? true : false
          continue
        }
        if (key === "min_date" || key === "max_date") {
          filterValues[key] = dayjs(value)
          continue
        }
        if (key === "page" || key === "limit" || value === "asc" || value === "desc") {
          if (key === "page") {
            setCurrentPage(parseInt(value))
          }
          sortPaginationValues[key] = value
        } else {
          filterValues[key] = value
        }
      }
    }

    setDefaultFilterValues({ ...filterValues, ...sortPaginationValues })
  }, [])

  return (
    <div>
      <Card>
        <Flex justify="space-between">
          <Typography.Title level={4} style={styles.title}>
            Danh sách đối tác
          </Typography.Title>
          <Space>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Làm mới
            </Button>
            <Button type="primary" onClick={() => showModal()} icon={<PlusOutlined />}>
              Thêm mới
            </Button>
          </Space>
        </Flex>

        <Card type="inner" style={styles.card}>
          <Filters
            filterRef={filterRef}
            defaultFilterValues={defaultFilterValues}
            onSubmit={onSearch}
            filters={
              [
                {
                  label: "Tìm theo người make action",
                  placeholder: "Tên người dùng",
                  name: "user_id",
                  type: "select-search",
                  options: dataUsers.map(user => ({ label: user.fullname, value: user.id })),
                },
                {
                  label: "Lọc loại logs",
                  placeholder: "Vui lòng chọn loại logs",
                  name: "action",
                  type: "select",
                  options: [
                    { value: "", label: "Tất cả" },
                    { value: LogActions.CREATE, label: Mapped_LogActions[LogActions.CREATE] },
                    { value: LogActions.READ, label: Mapped_LogActions[LogActions.READ] },
                    { value: LogActions.UPDATE, label: Mapped_LogActions[LogActions.UPDATE] },
                    { value: LogActions.DELETE, label: Mapped_LogActions[LogActions.DELETE] },
                    { value: LogActions.RESTORE, label: Mapped_LogActions[LogActions.RESTORE] },
                    { value: LogActions.CREATEMASS, label: Mapped_LogActions[LogActions.CREATEMASS] },
                  ],
                  defaultValue: "",
                },
                {
                  label: "Lọc theo ngày nợ từ",
                  placeholder: "Vui lòng chọn ngày từ",
                  name: "min_date",
                  type: "date",
                  defaultValue: "",
                },
                {
                  label: "đến",
                  placeholder: "Vui lòng chọn",
                  name: "max_date",
                  type: "date",
                  defaultValue: "",
                },
              ] as FilterComponent[]
            }
          />
        </Card>

        <Table
          rowClassName={() => "editable-row"}
          bordered
          dataSource={dataSource?.items}
          columns={columns as ColumnTypes}
          loading={loading}
          scroll={{ y: 700 }}
          onChange={onTableChanged}
          pagination={{
            total: dataSource?.meta.totalItems,
            pageSize: dataSource?.meta.itemsPerPage,
            current: currentPage,
          }}
          rowKey={record => record.id}
        />
      </Card>
    </div>
  )
}

export default List

const styles = {
  title: {
    marginTop: 0,
    marginBottom: 0,
  },
  card: {
    marginTop: "1.25rem",
    marginBottom: "1.25rem",
  },
}
