import { fetchStatistic } from "@/modules/dashboard/services/statistic.services"
import {
  DataStaticType,
  MAP_TitleChart,
  StatisticCard,
  StatisticData,
  TitleChart,
  lineChartOption,
  lineChartOptionByDay,
} from "@/modules/dashboard/types"
import {
  convertChartData,
  convertReceiptCategoryByDayChart,
  convertReceiptChartData,
  convertReceiptExpenseCategoryChart,
  convertReceiptIncomeCategoryChart,
  transformDebtStatistic,
  transformReceiptStatistic,
  transformStaffStatistic,
} from "@/utils"
import { Card, Col, Menu, MenuProps, Row, Statistic, Typography } from "antd"
import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import "../css/dashBoard.css"

const List: React.FC = () => {
  const { Title } = Typography
  const [menuSlider, setMenuSlider] = useState<string>("receipts")
  const [data, setData] = useState<StatisticCard[]>([])
  const [dataChart, setDataChart] = useState<DataStaticType[]>()
  const [isChartByDay, setIsChartByDay] = useState<boolean>(false)
  const onClick: MenuProps["onClick"] = e => {
    setMenuSlider(e.key)
  }

  const [dataStatistic, setDataStatistic] = useState<StatisticData>()
  useEffect(() => {
    fetchStatistic().then(res => {
      setDataStatistic(res.data)
    })
  }, [])

  useEffect(() => {
    if (dataStatistic) {
      if (menuSlider === "receipts") {
        setData(transformReceiptStatistic(dataStatistic.income_expense))
        setDataChart(convertReceiptChartData(dataStatistic.income_expense?.chart))
      } else if (menuSlider === "debts") {
        const dataPayable = transformDebtStatistic(dataStatistic.payable_debts)
        const dataReceivable = transformDebtStatistic(dataStatistic.receivable_debts)
        setData([dataPayable, dataReceivable])
        setDataChart(convertChartData(dataStatistic, "debts"))
      } else if (menuSlider === "staffs") {
        setData(transformStaffStatistic(dataStatistic.staffs))
        setDataChart(convertChartData(dataStatistic, "staffs"))
      } else if (menuSlider === "receipt_categories_income") {
        setData([])
        setDataChart(convertReceiptIncomeCategoryChart(dataStatistic.receipt_categories))
      } else if (menuSlider === "receipt_categories_expense") {
        setData([])
        setDataChart(convertReceiptExpenseCategoryChart(dataStatistic.receipt_categories))
      } else if (menuSlider === "receipt_categories_income_by_day") {
        setData([])
        setDataChart(convertReceiptCategoryByDayChart(dataStatistic.receipt_categories_by_day).incomeChartData)
        setIsChartByDay(true)
      } else if (menuSlider === "receipt_categories_expense_by_day") {
        setData([])
        setDataChart(convertReceiptCategoryByDayChart(dataStatistic.receipt_categories_by_day).expenseChartData)
        setIsChartByDay(true)
      }
    }
  }, [menuSlider, dataStatistic])

  const menuItems = [
    {
      label: "Thu chi",
      key: "receipts",
    },
    {
      label: "Công nợ",
      key: "debts",
    },
    {
      label: "Nhân viên",
      key: "staffs",
    },
    {
      label: "Thu danh mục theo tháng",
      key: "receipt_categories_income",
    },
    {
      label: "Chi theo danh mục theo tháng",
      key: "receipt_categories_expense",
    },
    {
      label: "Thu theo danh mục theo ngày",
      key: "receipt_categories_income_by_day",
    },
    {
      label: "Chi theo danh mục theo ngày",
      key: "receipt_categories_expense_by_day",
    },
  ]

  return (
    data && (
      <Row justify="space-between">
        <Card style={styles.cardLeftSide}>
          <Menu
            className="customButton"
            theme="light"
            mode="inline"
            defaultSelectedKeys={["receipts"]}
            onClick={onClick}
            items={menuItems}
          />
        </Card>
        <Card style={styles.cardRightSide}>
          <Title level={5}>{MAP_TitleChart[menuSlider as TitleChart]}</Title>
          <Row>
            {data?.map((item: StatisticCard) => (
              <Col span={6} key={item.name}>
                <Card style={styles.marginCardContent}>
                  <Statistic
                    title={item.name}
                    value={item.value}
                    valueStyle={{ color: "#3f8600" }}
                    prefix={item.prefix}
                    suffix={item.suffix}
                  />
                </Card>
              </Col>
            ))}
          </Row>
          <Card>
            <div className="linechart">
              <div style={{ marginBottom: "50px" }}>
                <Title level={5}>{MAP_TitleChart[menuSlider as TitleChart]}</Title>
              </div>
              {/* <div className="chartItems">
                <ul>
                  {dataChart?.map((item: any) => (
                    <li key={item.name}>
                      {item.hidden === true ? null : (
                        <span style={{ color: `${item.color}` }}>
                          <MinusOutlined /> {item.name}
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </div> */}
            </div>
            {dataChart && (
              <ReactApexChart
                options={isChartByDay ? lineChartOptionByDay : lineChartOption}
                series={dataChart}
                type="line"
                height={350}
                width={"100%"}
              />
            )}
          </Card>
        </Card>
      </Row>
    )
  )
}
const styles = {
  marginRow: {
    marginBottom: 24,
  },
  marginCardContent: {
    marginRight: 30,
    marginBottom: 20,
    padding: 0,
  },
  statisticValueStyle: {
    fontSize: 19,
    color: "#3f8600",
  },
  cardLeftSide: {
    width: "14%",
  },
  cardRightSide: {
    width: "84%",
  },
}

export default List
