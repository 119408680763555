import Filters, { FilterComponent } from "@/components/Filters"
import { Paths } from "@/constants"
import { fetchUsers } from "@/modules/manages/users/services/users.services"
import { InventoryType } from "@/modules/warehouses/inventories"
import { fetchInventories } from "@/modules/warehouses/inventories/services/inventories.services"
import { fetchUnits } from "@/modules/warehouses/inventories/services/units.services"
import {
  deleteInventoryTransaction,
  fetchInventoryTransactions,
} from "@/modules/warehouses/inventory-transactions/services/inventoryTransactions.services"
import {
  InventoryTransactionType,
  Mapped_transactionType,
  TransactionEnum,
  UnitType,
} from "@/modules/warehouses/inventory-transactions/types"
import UpsertForm from "@/modules/warehouses/inventory-transactions/view/UpsertForm"
import { ResponseData } from "@/types"
import { normalizeText } from "@/utils"
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons"
import { Button, Card, Flex, Popconfirm, Space, Table, Tag, Typography } from "antd"
import { SorterResult } from "antd/es/table/interface"
import dayjs from "dayjs"
import queryString from "query-string"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { AnyObject } from "yup/lib/types"

type EditableTableProps = Parameters<typeof Table>[0]

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>

const List: React.FC = () => {
  const [dataSource, setDataSource] = useState<ResponseData<InventoryTransactionType>>()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [needRefresh, setNeedRefresh] = useState(false)
  const [idEdit, setIdEdit] = useState<string>("")
  const [actionType, setActionType] = useState<string>("")
  const [currentPage, setCurrentPage] = useState(1)
  const navigate = useNavigate()
  const location = useLocation()
  const filterRef = useRef<{ filterFunction: () => void } | null>(null)
  const [defaultFilterValues, setDefaultFilterValues] = useState<{ [key: string]: any } | null>(null)
  const [filterValues, setFilterValues] = useState<{ [key: string]: string } | null>(null)
  const [sortPaginationValues, setSortPaginationValues] = useState<{ [key: string]: string } | null>(null)
  const [units, setUnits] = useState<{ value: number; label: string }[]>([])
  const [dataInventories, setDataInventories] = useState<{ value: string; label: string }[]>([])
  const [dataUsers, setDataUsers] = useState<{ value: string; label: string }[]>([])

  const getInventories = async () => {
    try {
      const response = await fetchInventories()
      if (response?.data) {
        const data = response.data.items.map((item: InventoryType) => ({ value: item.id, label: item.name }))
        setDataInventories(data)
      }
    } catch (error) {
      console.error("Error fetching positions:", error)
    }
  }

  const getUsers = async () => {
    try {
      const response = await fetchUsers()
      if (response?.data) {
        const data = response.data.items.map((item: any) => ({ value: item.id, label: item.fullname }))
        setDataUsers(data)
      }
    } catch (error) {
      console.error("Error fetching positions:", error)
    }
  }

  const onRefresh = () => {
    if (filterRef.current) {
      filterRef.current.filterFunction()
    }
    setSortPaginationValues(null)
    setNeedRefresh(!needRefresh)
  }

  const onDismissModal = () => {
    setOpen(false)
  }

  const onTableChanged = ((pagination, _, sorter: SorterResult<AnyObject>) => {
    const sortOptions: { [key: string]: string } = sorter.order
      ? { [sorter.field as string]: sorter.order === "descend" ? "desc" : "asc" }
      : {}
    const paginationOptions: { [key: string]: string } = {
      page: `${pagination.current}`,
      limit: `${pagination.pageSize}`,
    }

    if (pagination && pagination.current) {
      setCurrentPage(pagination.current)
    }

    setSortPaginationValues({ ...sortOptions, ...paginationOptions })
    const searchParams = queryString.stringify({ ...filterValues, ...sortOptions, ...paginationOptions })

    navigate(searchParams.length > 0 ? `${location.pathname}?${searchParams}` : location.pathname)
  }) as EditableTableProps["onChange"]

  const showModal = () => {
    setActionType("CREATE")
    setOpen(true)
  }

  const showModalEdit = (id: string) => {
    navigate(`${Paths.INVENTORYTRANSACTIONS.LIST.PATH}/${id}${location.search}`)
    setIdEdit(id)
    setOpen(true)
    setActionType("UPDATE")
  }

  const onSearch = useCallback(
    (values: any) => {
      let newValue = { ...values, ...sortPaginationValues }

      if (values.search) {
        newValue = { ...newValue, search: normalizeText(values.search) }
      }

      if (newValue.min_created_at) {
        newValue = { ...newValue, min_created_at: dayjs(newValue.min_created_at).format("YYYY-MM-DD") }
      }

      if (newValue.max_created_at) {
        newValue = { ...newValue, max_created_at: dayjs(newValue.max_created_at).format("YYYY-MM-DD") }
      }

      const searchParams = queryString.stringify(newValue)
      const newUrl = searchParams.length > 0 ? `${location.pathname}?${searchParams}` : location.pathname
      navigate(newUrl)
    },
    [sortPaginationValues],
  )

  useEffect(() => {
    if (!filterValues && !sortPaginationValues) return
    const params = { ...filterValues, ...sortPaginationValues }
    setLoading(true)
    fetchInventoryTransactions(params).then((res: any) => {
      if (sortPaginationValues && sortPaginationValues.page) {
        if (sortPaginationValues.page > res.data.meta.totalPages && res.data.meta.totalPages > 0) {
          const searchLocation = queryString.parse(location.search)
          const newParams = queryString.stringify({ ...searchLocation, page: `${res.data.meta.totalPages}` })
          const newUrl = newParams.length > 0 ? `${location.pathname}?${newParams}` : location.pathname
          navigate(newUrl)
        }
      }
      setDataSource(res.data)
      setLoading(false)
    })
  }, [filterValues, sortPaginationValues, needRefresh])

  useEffect(() => {
    const searchParams = queryString.parse(location.search)
    const filterValues: { [key: string]: any } = {}
    const sortPaginationValues: { [key: string]: string } = {}
    for (const key of Object.keys(searchParams)) {
      const value = searchParams[key]
      if (value && typeof value === "string") {
        if (key === "page" || key === "limit" || value === "asc" || value === "desc") {
          sortPaginationValues[key] = value
        } else {
          filterValues[key] = value
        }
      }
    }

    setFilterValues(filterValues)
    setSortPaginationValues(sortPaginationValues)
  }, [location.search])

  useEffect(() => {
    fetchUnits().then(res => {
      if (res.data && res.data.length) {
        const data = res.data.map((item: UnitType) => ({ value: item.id, label: item.name }))
        setUnits(data)
      }
    })
    getInventories()
    getUsers()
    const searchParams = queryString.parse(location.search)
    const filterValues: { [key: string]: any } = {}
    const sortPaginationValues: { [key: string]: string } = {}
    for (const key of Object.keys(searchParams)) {
      const value = searchParams[key]
      if (value && typeof value === "string") {
        if (key === "min_created_at" || key === "max_created_at") {
          filterValues[key] = dayjs(value)
          continue
        }
        if (value === "true" || value === "false") {
          filterValues[key] = value === "true" ? true : false
          continue
        }
        if (key === "page" || key === "limit" || value === "asc" || value === "desc") {
          if (key === "page") {
            setCurrentPage(parseInt(value))
          }
          sortPaginationValues[key] = value
        } else {
          filterValues[key] = value
        }
      }
    }

    setDefaultFilterValues({ ...filterValues, ...sortPaginationValues })
  }, [])

  const handleDelete = async (record: InventoryTransactionType) => {
    await deleteInventoryTransaction(record)
    setNeedRefresh(!needRefresh)
  }

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: "5%",
    },
    {
      title: "Tên hàng hoá",
      dataIndex: "inventory",
      key: "inventory",
      width: "18%",
      render: inventory => inventory.name,
      sorter: (a, b) => a.inventory.name.localeCompare(b.inventory.name),
    },
    {
      title: "Số lượng",
      dataIndex: "quantity",
      key: "quantity",
      width: "12%",
      render: quantity => (quantity && quantity > 0 ? quantity : null),
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: "Đơn vị",
      dataIndex: "inventory",
      key: "inventory",
      render: inventory => units.find(unit => unit.value === inventory.unit_id)?.label,
      width: "12%",
    },
    {
      title: "Loại giao dịch",
      dataIndex: "transaction_type",
      key: "transaction_type",
      width: "10%",
      render: (transaction_type: TransactionEnum) => {
        return (
          <Tag color={transaction_type === TransactionEnum.IN ? "success" : "error"}>
            {Mapped_transactionType[transaction_type]}
          </Tag>
        )
      },
    },
    {
      title: "Ghi chú",
      dataIndex: "notes",
      key: "notes",
      width: "15%",
    },
    {
      title: "Người kiểm kê",
      dataIndex: "user",
      key: "user",
      width: "15%",
      render: user => user.fullname,
      sorter: (a, b) => a.user.fullname.localeCompare(b.user.fullname),
    },
    {
      title: "Ngày diễn ra",
      dataIndex: "transaction_date",
      key: "transaction_date",
      render: transaction_date => transaction_date && dayjs(new Date(transaction_date)).format("DD/MM/YYYY"),
      width: "13%",
      sorter: (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    },
    {
      title: "Ngày tạo",
      dataIndex: "created_at",
      key: "created_at",
      render: created_at => created_at && dayjs(new Date(created_at)).format("HH:mm DD/MM/YYYY"),
      width: "13%",
      sorter: (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    },
    {
      title: "Thao tác",
      dataIndex: "operation",
      render: (_, record) =>
        dataSource && dataSource?.items.length >= 1 ? (
          <Space>
            <Button ghost type="primary" shape="round" size="small" onClick={() => showModalEdit(record?.id)}>
              Sửa
            </Button>
            <Popconfirm
              title="Bạn có chắc chắn muốn xóa?"
              onConfirm={() => handleDelete(record as InventoryTransactionType)}
            >
              <Button type="default" shape="round" danger size="small">
                Xóa
              </Button>
            </Popconfirm>
          </Space>
        ) : null,
      width: "15%",
    },
  ]

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record: InventoryTransactionType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
  })

  return (
    <div>
      <Card>
        <Flex justify="space-between">
          <Typography.Title level={4} style={styles.title}>
            Danh sách lịch sử xuất nhập kho
          </Typography.Title>
          <Space>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Làm mới
            </Button>
            <Button type="primary" onClick={() => showModal()} icon={<PlusOutlined />}>
              Tạo phiếu xuất / nhập kho
            </Button>
          </Space>
        </Flex>

        <Card type="inner" style={styles.card}>
          <Filters
            filterRef={filterRef}
            defaultFilterValues={defaultFilterValues}
            onSubmit={onSearch}
            filters={
              [
                {
                  label: "Hàng hoá",
                  placeholder: "Tên hàng hoá",
                  name: "inventory_id",
                  type: "select-search",
                  options: dataInventories,
                },
                {
                  label: "Người kiểm kê",
                  placeholder: "Người kiểm kê",
                  name: "user_id",
                  type: "select-search",
                  options: dataUsers,
                },
                {
                  label: "Loại giao dịch",
                  name: "transaction_type",
                  type: "select",
                  options: [
                    { value: "", label: "Tất cả" },
                    { value: TransactionEnum.IN, label: Mapped_transactionType[TransactionEnum.IN] },
                    { value: TransactionEnum.OUT, label: Mapped_transactionType[TransactionEnum.OUT] },
                  ],
                  defaultValue: "",
                },
                {
                  label: "Lọc ngày diễn ra",
                  placeholder: "Vui lòng chọn ngày từ",
                  name: "min_transaction_date",
                  type: "date",
                  defaultValue: "",
                },
                {
                  label: "đến",
                  placeholder: "Vui lòng chọn",
                  name: "max_transaction_date",
                  type: "date",
                  defaultValue: "",
                },
              ] as FilterComponent[]
            }
          />
        </Card>

        <UpsertForm
          openProps={open}
          cancelProps={onDismissModal}
          refreshProps={onRefresh}
          actionType={actionType}
          idEdit={idEdit}
        />
        <Table
          rowClassName={() => "editable-row"}
          bordered
          dataSource={dataSource?.items}
          columns={columns as ColumnTypes}
          loading={loading}
          scroll={{ y: 700 }}
          onChange={onTableChanged}
          pagination={{
            total: dataSource?.meta.totalItems,
            pageSize: dataSource?.meta.itemsPerPage,
            current: currentPage,
          }}
          rowKey={record => record.id}
        />
      </Card>
    </div>
  )
}

export default List

const styles = {
  title: {
    marginTop: 0,
    marginBottom: 0,
  },
  card: {
    marginTop: "1.25rem",
    marginBottom: "1.25rem",
  },
}
